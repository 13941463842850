/* NotFoundPage Styles with Footer Adjustment */
.notFoundPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 55vh; /* Adjusted for footer height */
    padding: 4rem; /* Adequate padding all around */
    text-align: center; /* Centers the text within the flex container */
}
