.bitmovin__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 7rem 0;
}

.bitmovin__intro,
.bitmovin__reflection,
.bitmovin__skills,
.bitmovin__feedback {
    max-width: 800px;
    text-align: center;
    margin-bottom: 2rem;
}

.bitmovin__video video {
    width: 100%;
    max-width: 800px;
    border-radius: 1rem;
    box-shadow: var(--shadow);
}

.skills__container {
    grid-template-columns: repeat(2, 350px);
    column-gap: 3rem;
    justify-content: center;
}

.skills__content {
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2rem 4rem;
    border-radius: 1.25rem;
}

.skills__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    text-align: center;
    margin-bottom: var(--mb-1-5);
}

.skills__box {
    display: flex;
    justify-content: center;
    column-gap: 2.5rem;
}

.skills__group {
    display: grid;
    align-items: flex-start;
    row-gap: 1.5rem;
}

.skills__data {
    display: flex;
    column-gap: 0.5rem;
}

.skills .bx-badge-check {
    font-size: 1rem;
    color: var(--title-color);
}

.skills__name {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    line-height: 18px;
}

.skills__level {
    font-size: var(--tiny-font-size);
}

.bitmovin__reflection {
    margin-top: 2rem;
}


.bitmovin__description {
    font-size: var(--normal-font-size);
    font-weight: var(--font-regular);
    color: var(--text-color);
    max-width: 800px;
    text-align: center;
    margin: 0 auto; /* Center the block */
    padding: 0 1.5rem; /* Ensure padding for consistent alignment */
}

.sign-language-image {
    max-width: 600px; /* Ensures the image doesn't exceed a reasonable width */
    width: 100%; /* Makes the image responsive */
    margin: 1.5rem auto; /* Adds space above and below the image and centers it */
    display: block; /* Centers the image within its container */
    border-radius: 1rem; /* Matches the style of other elements with rounded corners */
    box-shadow: var(--shadow); /* Adds a subtle shadow like your video element */
    padding-bottom: 50px;
}


    
/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .skills__container {
        grid-template-columns: max-content;
        row-gap: 2rem;
    }
}

/* For medium devices */
@media screen and (max-width: 576px) {
    .skills__container {
        grid-template-columns: 1fr;
    }

    .skills__content {
        padding: 1.5rem;
    }
    .sign-language-image {
        max-width: 80%; /* Limits the image width to 80% of its container */
    }
 
}

/* For small devices */
@media screen and (max-width: 350px) {
    .skills__box {
        column-gap: 1.25rem;
    }
    .skills__name {
        font-size: var(--small-font-size);
    }
}




